import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Hero from './hero/hero';
import styled, {css} from "styled-components";
import 'bootstrap/dist/css/bootstrap.min.css';
import Features from "./sections/features";
import Descriptor from "./sections/descriptor";
import CDNavbar from "./components/navbar"
import Footer from "./sections/footer";
import Scopes from "./sections/scopes";
import TechStack from "./sections/tech-stack";
import AimingHigh from "./sections/aiming-high";
import CookieConsent from "react-cookie-consent";


// Sticky header
// Hero
// Features
// Mission
// More about features? do góry to? 
//// Softare Development
////// Our Technical stack
//// Trainings
//// Consulting
// Contact Us

export const MainContainer = styled(Container)`
    padding:0;
  `
const mission = {
    header: "Our Mission",
    content: [`Programming is a process, just like managing business and product life.
     Our mission is to create software that is easy to understand, maintainable, tested, reliable,
      and adaptable to business changes - or just the best possible.`,
     `Everything we need from our clients is to introduce us to their needs, plans, processes, and problems.`, 
     `We know that it's possible to deliver quality software constantly with the methodologies we use,
      showing the progress of work from iteration to iteration - looking for new opportunities.`],
    footer: 'So, If you\'re aiming high'
}

export default function Home() {

  return (
    <MainContainer fluid>
      <CDNavbar />
      <Hero
        id={"hero"}
        title={"Place where software is born"}
        content={"We're cutting software to your business"}
      />
      <GenericSection sectionId="mission">
      <Descriptor
              header={mission.header}
              content={mission.content}
            >
              <AimingHigh titleText={mission.footer}/>          
            </Descriptor>
      </GenericSection>
      <Sections sections={prepareSections()} />
      <Footer />
      <CookieConsent
          location="bottom"
          buttonText="OK, It's fine"
          buttonStyle={{
            display: "inline-block",
            fontWeight: 400,
            color: "#fff",
            backgroundColor: "#007bff",
            borderColor: "#007bff",            
            border: "1px solid transparent",
            padding: "0.375rem 0.75rem",
            fontSize: "1rem",
            lineHeight: 1.5,
            borderRadius: "0.25rem",
            transition: "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out"
          }}
          style={{backgroundColor:"rgba(120,120,120,1)"}}
          cookieName="gatsby-gdpr-google-analytics">
        Cookie files are helping us to enhance your experience. You can disable them in your browser settings. <a href="/cookies">(more info)</a>
      </CookieConsent>
    </MainContainer>
  )
}

const Sections = (props) => {
  const { sections } = props;
  return (<Container>
    {sections}
  </Container>);
}

const Section = styled.section`
    min-height: 85vh;
    display: flex;
    justify-content: center;
    align-items: space-evenly;
    flex-direction: column;
    text-align: center;
    width: 100%;
    overflow:hidden;
  `;

const GenericSection = (props) => {

  return (
    <Row>
      <Col>
        <Section
            id={props.sectionId}
            isEven={props.isEven}
        >
          {props.children}
        </Section>
      </Col>
    </Row>
  )
    ;
}

function prepareSections() {
  //TODO move every section declaration do separate file. everything is inlined for sake of development

  const cardsData = [
    {
      imgSrc: "/images/features/coding.png",
      imgAlt: "Software",
      headerText: "Software Development",
      paragraphText: "Tailor made software according to your needs. ",
      sectionReference: "#software",
      buttonText: "Learn more",
      buttonVariant: "outline-secondary",
      buttonDisabled: false
    },
    {
      imgSrc: "/images/features/consulting.png",
      imgAlt: "Consulting",
      headerText: "Consulting",
      paragraphText: "Need to solve a particular problem?",
      sectionReference: "#contact-us",
      buttonText: "Contact us",
      buttonVariant: "outline-primary",
      buttonDisabled: false
    },
    {
      imgSrc: "/images/features/presentation.png",
      imgAlt: "Training",
      headerText: "Trainings",
      paragraphText: "We can help you improve your skills. ",
      sectionReference: "#trainings",
      buttonText: "Coming soon",
      buttonVariant: "outline-secondary",
      buttonDisabled: true
    }
  ];

  const softwareDevelopment = {
    header: "Software Development",
    content: [`We craft tailor-made software according to your needs.`,
    `We care about all the aspects of software quality because we believe that software must be built like construction 
    - with planning, responsibility, attention to detail in mind. It’s our core value to make the most failsafe possible. `,
    `We specialize in software design, making decisions to provide exactly what's needed to make a product work better than intended. 
    We know how to create full solutions and have established a workflow of providing every aspect on time.`]
  }

  const softwareDevelopmentScopes = [
    {
      header: "Deep dive into your business",
      content: `Before coding, we're getting sure that we understand which problems prepared for you software have to solve. 
      Basing on your requirements we're preparing an understandable model by using techniques like event-storming, value mapping, etc.`,
      image: "/images/svg/understanding.svg",
      imageAlt: "understanding",
      listTitle: "Everything to understand:",
      list: ["Needs", "Processes", "Problems", "Opportunities"]
    },
    {
      header: "Full insight into our work",
      content: `We make every effort to make you aware of your system. 
      For this reason, you have full insight into our work, 
      and we take care of business and technical solutions monitoring 
      to provide any metrics you desire.`,
      image: "/images/svg/process.svg",
      imageAlt: "process",
      list: ["New value on a daily basis", 
      "Working in a feedback loop", 
      "\“Continous Delivery\”", 
      "Monitoring"]
    },
    {
      header: "Collaboration",
      //TODO
      // content: `We make every effort to make you aware of your system. 
      // For this reason, you have full insight into our work, 
      // and we take care of business and technical solutions monitoring 
      // to provide any metrics you desire.`,
      image: "/images/svg/collaboration.svg",
      imageAlt: "collaboration",
      list: ["Instant contact", 
      "Conversation over the rules", 
      "Trust"]
    },
    {
      header: "Quality",
      //TODO
      // content: `We make every effort to make you aware of your system. 
      // For this reason, you have full insight into our work, 
      // and we take care of business and technical solutions monitoring 
      // to provide any metrics you desire.`,
      image: "/images/svg/quality2.svg",
      imageAlt: "quality",
      list: ["Automation testing", 
      "Good practices", 
      "Flexible solutions",
      "Cheaply maintenance later"
    ]
    }

  ]

  const trainings = {
    header: "Training",
    content: "If your software development teams need some in-house training, we got you covered. "
      + "We specialize in following domains:",
    items: [
      "Testing automation", "Secure testing", "Shipping to production",
      "Devops culture"
    ]
  }

  const preparedSections = [    
    {
      id: "features",
      sectionName: "FeaturesNew",
      content: (<Features cardsData={cardsData} />)
    },
    {
      id: "software",
      sectionName: "Software Development",
      content: (
        <Descriptor
          header={softwareDevelopment.header}
          content={softwareDevelopment.content}>
          <Scopes data={softwareDevelopmentScopes}/>
          <TechStack/>
        </Descriptor>
        // <Toggle
        //     text={"Click! <<"}
        //     first={
        //         <Descriptor
        //             header={"Placeholder"}
        //             content={"there should be lorem"}
        //         />}
        //     second={
        //       <Descriptor
        //           header={softwareDevelopment.header}
        //           content={softwareDevelopment.content}>
        //         <Scopes data={softwareDevelopmentScopes}/>
        //       </Descriptor>}
        // />
      )
    },
    // {
    //   id: "trainings",
    //   sectionName: "Trainings",
    //   content: (
    //     <Descriptor
    //       header={trainings.header}
    //       content={trainings.content}
    //       items={trainings.items}
    //     />
    //   )
    // },
  ];

  return (
    <React.Fragment>
      {
        preparedSections.map((section, index) => {
          return (
            <GenericSection sectionId={section.id} isEven={index % 2 === 0}>
              {section.content}
            </GenericSection>);
        })
      }
    </React.Fragment>
  );

}